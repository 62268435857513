import React from 'react'
import Nakanoen from './images/nakanoen'

export default function Partner() {
  const contents = '世界緑茶コンテスト2010<br />最高金賞＆パッケージ大賞　ダブル受賞'
  const note = '株式会社サンリクは、「株式会社マルサン中野園」と<br />パートナー提携をしています。'

  return (
    <div className="text-center">
      <p className='text-sm mb-2'>
      茶師七段・日本茶鑑定士が品質にこだわり厳選
      </p>
      <a href="http://nakanoen.co.jp/" target="_blank" rel="noopener noreferrer" className="w-60 md:w-72 mx-auto border-4 border-gray-600 block">
        <div className="p-4">
          <Nakanoen />
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: contents }}
          className="text-sm bg-gray-600 py-4 text-white"
        />
      </a>
      <p
        dangerouslySetInnerHTML={{ __html: note }}
        className="mt-4 text-sm"
      />
    </div>
  )
}
