import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import StoreBanner from '../components/store-banner'
import About from '../components/about'
import Contents from '../components/contents'
import Partner from '../components/partner'
import SEO from '../components/seo'

export default function Index() {
  return (
    <Layout>
      <SEO />
      <Hero />
      <StoreBanner />
      <About />
      <Contents />
      <Partner />
    </Layout>
  )
}
