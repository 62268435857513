import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Hero() {
  const hero = {
    catchphrase: '海からの贈り物を<br />あなたへ届けたい',
    contents: '「おいしい」と笑顔になれる<br />' +
      '商品を届けたい<br />' +
      '「またこれが食べたい」と<br />' +
      '思ってもらえる商品を作りたい。<br />' +
      'それが、日本国産にこだわる理由です。'
  }
  return (
    <div style={{ display: 'grid' }}>
      <StaticImage
      style={{ gridArea: '1/1', }}
      layout="fullWidth"
      aspectRatio={2800 / 1200}
      alt=""
      src={ '../images/hero.jpg' }
      formats={[ 'auto', 'webp', 'avif' ]}
    />
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
        }}
      >
        <div className="w-full px-10 md:px-20 py-20 md:py-0">
          <h2
            className="text-3xl md:text-4xl md:text-6xl font-medium text-purple-900 md:leading-20"
            dangerouslySetInnerHTML={{ __html: hero.catchphrase }}
          />
          <p
            className="text-base md:text-2xl md:leading-10 font-medium mt-10"
            dangerouslySetInnerHTML={{ __html: hero.contents }}
          />
        </div>
      </div>
    </div>
  )
}
