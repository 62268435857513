import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function StoreBanner() {
  const text =
    'オンラインストアはこちら<br />' +
    '2万円以上お買い上げで送料無料'
  return (
    <section className="max-w-screen-lg mx-auto mt-10 md:mt-32 px-6 md:px-0">
      <a
        href="https://sanrikustore.com"
        target="_blank"
        rel="noreferrer noopener"
        className="shadow-xl block relative rounded-xl"
      >
        <StaticImage
          src="../images/banner-store.jpg"
          alt=""
          layout="fullWidth"
          placeholder="blurred"
          formats={[ 'auto', 'webp' ]}
          className="rounded-xl"
        />
        <div className="absolute top-0 left-0 bg-black opacity-40 w-full h-full rounded-xl" />
        <span
          className="block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg md:text-4xl
          font-medium w-full text-center text-white md:leading-16"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </a>
    </section>
  )
}
