import React from 'react'

export default function About() {
  const contents = '私たち「サンリク」は西東京の多摩地区である調布市に本社と工場と併設した直営店を設け、<br class="hidden md:inline-block" />' +
    '日本の海が育んだ昆布・若布 その他海藻類の卸売りおよび加工販売を行っています。<br />' +
    'わかめは「岩手県産・徳島県産」、昆布は「北海道産」、もずくは「沖縄県産」など、国産海藻物を中心に取り扱い、<br class="hidden md:inline-block" />' +
    'お中元やお歳暮といった贈答品としても人気は高く、<br class="hidden md:inline-block" />' +
    'リピートして購入する人や味に満足して問合せをする人も多くご好評いただいています。<br />' +
    '“日本国内産の海藻を使用”、“仕入れから加工まで自社一貫体制”、“加工はすべて手作業”という、<br class="hidden md:inline-block" />' +
    'おいしさと安全に徹底したこだわりを持ってご提供させていただきます。'

  return (
    <section className="max-w-screen-lg mx-auto mt-20 md:mt-32 md:text-center px-6 md:px-0">
      <h2 className="text-3xl font-medium">サンリクについて</h2>
      <p
        className="text-lg leading-9 mt-10"
        dangerouslySetInnerHTML={{ __html: contents }}
      />
    </section>
  )
}
