import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Contents() {
  return (
    <section className="mt-16 md:mt-32 mb-40 px-6 md:px-0">
      <div className="grid grid-cols-1 md:grid-cols-3 max-w-screen-lg mx-auto gap-12 md:gap-8">
        <div className="rounded-xl shadow-md">
          <StaticImage
            src="../images/thumb-message.jpg"
            alt=""
            layout="fullWidth"
            placeholder="blurred"
            formats={[ 'auto', 'webp' ]}
            className="rounded-t-md"
          />
          <div className="py-6 px-6">
            <h2 className="text-xl font-medium">ごあいさつ</h2>
            <p className="mt-4 leading-7">「これが一番おいしい」というおばあちゃんの言葉が、社長がおいしさを追及する原点。そして創業者から引き継ぐ“義理・人情・恩返し”の精神が今のサンリクの原動力となっています。</p>
          </div>
        </div>
        <div className="rounded-xl shadow-md">
          <StaticImage
            src="../images/thumb-feature.jpg"
            alt=""
            layout="fullWidth"
            placeholder="blurred"
            formats={[ 'auto', 'webp' ]}
            className="rounded-t-md"
          />
          <div className="py-6 px-6">
            <h2 className="text-xl font-medium">こだわり</h2>
            <p className="mt-4 leading-7">こだわるのは、「おいしさ」、「日本国内産」、「食の安全」。例えば、よりおいしい「わかめ」を届けるために、海の環境を見てその年ごとに仕入れる「浜」を変えるという方法もとっています。</p>
          </div>
        </div>
        <div className="rounded-xl shadow-md">
          <StaticImage
            src="../images/thumb-product.jpg"
            alt=""
            layout="fullWidth"
            placeholder="blurred"
            formats={[ 'auto', 'webp' ]}
            className="rounded-t-md"
          />
          <div className="py-6 px-6">
            <h2 className="text-xl font-medium">商品案内</h2>
            <p className="mt-4 leading-7">わかめは「岩手県産・徳島県産」、昆布は「北海道産」、もずくは「沖縄県産」など、サンリクと地元業者の信頼でつながれた独自のネットワークで仕入れた海藻類をラインナップしています。</p>
          </div>
        </div>
      </div>
    </section>
  )
}
